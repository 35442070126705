import './../App.css'
import React, { useState } from 'react'
import { Link, useLocation } from "react-router-dom"; 
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import Logo from './../assets/logo.png';

export const Navbar = () => {
  let location = useLocation();
  let currentPage = location.pathname;
  let MenuList = [
    { name: "HOME", link: "/" },
    { name: "ABOUT US", link: "/aboutus" },
    { name: "PROGRAMS", link: "/programs" },
    //{name:"OUR PURPOSE",link:"/ourpurpose"},
    { name:"NEWS & EVENTS",link:"/newsandevents" },
    { name: "CONTRIBUTE", link: "/contribute" },
    { name:"CAREER",link:"/career"},
    { name: "CONTACT US", link: "/contactus" }
  ];
  let [open, setOpen] = useState(false);
  return (
    /*fixed top-0 left-0*/
    <div className='topbar w-full h-[100px] fixed top-0 left-0 z-50'>
      <nav className='w-full h-full'>
      <div className='flex items-center justify-between bg-basecolor py-[25px] px-14 h-[100%]'>
        <div className='cursor-pointer flex items-center'>
          <Link to='/'><img src={Logo} alt='water-flow-foundation-logo' className='h-[50px] w-[247px] py-0' /></Link>
        </div>
        <div onClick={() => setOpen(!open)} className='text-3xl text-white absolute right-10 top-[30%] cursor-pointer md:hidden'>
          {open ? <AiOutlineClose /> : <AiOutlineMenu />}
        </div>
        <ul className={`md:flex pb-0 absolute md:static bg-basecolor md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-[100px] ' : 'top-[-490px]'}`}>
          {
            MenuList.map((link) => (
              <li key={link.name} className={`nav-item md:ml-1 text-l md:my-0 my-7 md:h-[100px] md:pt-10 h-full ${currentPage === link.link ? "active" : ""}`}>
                <Link to={link.link} className='font-ceraReg text-white hover:text-fontHover md:mx-2 duration-500'>{link.name}</Link>
              </li>
            ))
          }
        </ul>
      </div>
      </nav>
    </div>
  )
}

export default Navbar