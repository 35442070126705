import React, { useState, useEffect } from 'react';
import { getEvents } from './../api/call'
import LoadingIndicator from './LoadingIndicator';
import { AiOutlineCaretRight } from 'react-icons/ai'
import { DetailModal } from './DetailModal'

export const News = () => {

    const [showModal, setShowModal] = useState(false)
    const [newsDetail, setNewsDetail] = useState()
    const [events, setEvents] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const onClose = () => { setShowModal(false); setNewsDetail(null) }

    const maxlimit = window.outerWidth >= 1024 ? 120 : 140;

    useEffect(() => {
        if (!events) dataEvents();
    });

    const dataEvents = async () => {
        const data = await getEvents();
        setEvents(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    return (
        <>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
        <div id='newsandevents' className='w-[80vw] mx-auto text-center relative justify-center focis:bg-black'>
            <p className='text-fontColor font-ceraMid text-[3vw]'>News & Events</p>
            <p className='font-quicksandBold opacity-40 text-fontColor absolute top-[50%] left-[-15vw] -rotate-90 text-[1.5vw] tracking-[10px]'>NEWS & EVENTS</p>
            {events.length > 0 ? <div className='grid grid-rows-3 grid-cols-5 py-4 gap-2 md:gap-4 h-full lg:h-[70vh] w-full'>
                <div className='w-full h-full col-span-5 lg:col-span-3 row-span-3'>
                    <img className="object-cover bg-no-repeat bg-center w-full lg:h-full h-[90%]" src={events[0].image_url_path} alt='/' />
                    <div className='w-[80%] m-auto z-10 relative bottom-[25%]'>
                        <p className='font-ceraMid text-white text-left lg:text-[1.7vw] text-[3vw] w-full'>{events[0].title}</p>
                        <p className='font-ceraReg text-white text-left lg:text-[1vw] text-[2.5vw] w-full'>{events[0].created_at}</p>
                    </div>
                    <div className='w-full h-[10%] relative bottom-[15%]'>
                        <p className='font-ceraMid text-fontColor text-lg w-full flex cursor-pointer' onClick={() => { setShowModal(true); setNewsDetail({ "img": events[0].image_url_path, "title": events[0].title, "date": events[0].created_at, "details": events[0].description }) }}>Read more<AiOutlineCaretRight className='flex text-2xl pt-1.5' /></p>
                    </div>
                </div>
                {events.map((j, i) => (
                    1 <= i ?
                        <div key={`${j.title}-${i}`} className='w-full h-full object-cover lg:col-span-2 col-span-5 row-span-1 grid grid-rows-6 grid-cols-5'>
                            <div className='w-full h-full row-span-6 col-span-2'>
                                <img className="object-cover bg-no-repeat bg-center w-full h-full" src={j.image_url_path} alt='/' />
                            </div>
                            <div className='w-full h-full row-span-1 col-span-3'>
                                <p className='font-ceraMid text-fontColor text-l text-left m-auto w-[90%] h-full'>{j.title}</p>
                            </div>
                            <div className='w-full h-full row-span-1 col-span-3'>
                                <p className='font-ceraReg text-[#575757] text-xs text-left m-auto w-[90%] h-full'>{j.created_at}</p>
                            </div>
                            <div className='w-full h-full row-span-3 col-span-3'>
                                <p className='font-ceraReg text-[#575757] text-xs text-left m-auto w-[90%] h-full'>{((j.description).length > maxlimit) ? (((j.description).substring(0, maxlimit - 3)) + '...') : j.description}</p>
                            </div>
                            <div className='w-full h-full row-span-1 col-span-3'>
                                <p className='font-ceraMid text-fontColor text-xs text-left m-auto w-[90%] h-full flex cursor-pointer' onClick={() => { setShowModal(true); setNewsDetail({ "img": j.image_url_path, "title": j.title, "date": j.created_at, "details": j.description }) }}>Read more<AiOutlineCaretRight className='flex text-l pt-1' /></p>
                            </div>
                        </div>
                        : null
                ))}
            </div>
            :<div className='w-full h-[calc(100vh-271px)]'></div>}
            <div className='w-full h-[110px]'></div>
            <DetailModal visible={showModal} onClose={onClose} arr={newsDetail} />
        </div>
        )}
        </>
    )
}

