import './../App.css'

const LoadingIndicator = () => {
    return (
        <>
        <div className='loadingCont'>
            <div className='loading'>
            </div>
        </div>
        </>
    );
};

export default LoadingIndicator;