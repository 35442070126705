import React, { useState, useEffect } from 'react';
import { getPrograms } from './../api/call'
import LoadingIndicator from './LoadingIndicator';

export const Programs = () => {

    const [programs, setPrograms] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!programs) dataPrograms();
    });

    const dataPrograms = async () => {
        const data = await getPrograms();
        setPrograms(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    return (
        <>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
        <div id='programs' className='max-w-[80vw] h-auto mx-auto py-5 px-4 text-center relative justify-center'>
            <p className='font-quicksandBold opacity-40 text-fontColor absolute top-[50%] md:left-[-15vw] left-[-15vw] -rotate-90 text-[1.5vw] tracking-[10px]'>OUR JOURNEY</p>
            <p className='text-fontColor font-ceraMid text-[3vw]'>Programs</p>
            {programs.length > 0 ? <div className='grid grid-rows-2 grid-cols-4 py-4 gap-2 md:gap-4 h-auto'>
                <div className='w-full h-full md:col-span-2 col-span-4 row-span-2'>
                    <img className="object-cover bg-no-repeat bg-center w-full h-[70%] rounded-md" src={programs[0].image_url_path} alt='/' />
                    <div className='w-[95%] m-auto z-10 relative'>
                        <p className='font-ceraMid text-fontColor text-left lg:text-[2vw] text-[3vw] w-full'>{programs[0].title}</p>
                        <p className='font-ceraReg text-hoverColor text-left lg:text-[1vw] text-[2.5vw] w-full'>{programs[0].description}</p>
                    </div>
                </div>
                {programs.map((j, i) => (
                    1 <= i ?
                        <div key={`${j.title}-${i}`} className='w-full object-cover md:col-span-1 col-span-2 row-span-1 grid grid-rows-5 grid-cols-1'>
                            <div className='w-full h-full row-span-4 col-span-1'>
                                <img className="object-cover bg-no-repeat bg-center w-full h-full rounded-2xl" src={j.image_url_path} alt='/' />
                            </div>
                            <div className='w-full h-full row-span-1 col-span-1'>
                                <p className='font-ceraMid text-fontColor md:text-md lg:text-xl text-lg text-left m-auto w-[90%] h-full'>{j.title}</p>
                            </div>
                        </div>
                        : null
                ))}
            </div>
            :<div className='w-full h-[calc(100vh-201px)]'></div>}
        </div>
        )
    }
    </>
    )
}
