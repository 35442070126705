import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'

export const DetailModal = ({ visible, onClose, arr }) => {

    const newsDetail = arr

    if (!visible) return false

    return (
        <div className='fixed w-full h-[100vh] top-0 left-0 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center z-50'>
            <div className='w-[60%] h-[90%] bg-white rounded-xl flex'>
                <div className='w-[90%] h-[90%] m-auto'>
                    <div className='w-full h-[10%] items-center justify-end flex'>
                        <AiOutlineClose className='text-black text-xl' onClick={onClose} />
                    </div>
                    <p className='w-full h-[10%] text-fontColor text-left md:text-[2vw] text-[3vw] font-ceraMid'>{newsDetail.title}</p>
                    <div className='w-full h-[75%] overflow-y-scroll'>
                        <img className="object-cover bg-no-repeat mx-auto md:w-[40%] w-full h-auto" src={newsDetail.img} alt='/' />
                        <p className='w-full h-auto text-fontHover text-left md:text-[1.17vw] text-[2vw] font-ceraMid mt-5'><div dangerouslySetInnerHTML={{ __html: newsDetail.details }} /></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
