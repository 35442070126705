import React, { useState, useEffect } from 'react';
import { getPurpose } from './../api/call'
import Bg from './../assets/bg-4.png';

export const Purpose = (props) => {

  const [purpose, setPurpose] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!purpose) dataPurpose();
  });

  const dataPurpose = async () => {
      const data = await getPurpose();
      setPurpose(data);
      setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <>
    {isLoading && null}
    {!isLoading && (
    <div id='ourpurpose' className="relative justify-center h-[calc(100vh_-_100px)] mt-[-80px] 2xl:mt-[-160px] z-10">
      <img src={Bg} alt='/' className="object-cover md:object-fill bg-no-repeat bg-center w-full h-full" />
      <p className='font-quicksandBold opacity-40 text-white absolute top-[50%] left-[-5vw] -rotate-90 text-[1.5vw] tracking-[10px]'>WHO WE ARE</p>
      <p className='font-quicksandBold opacity-40 text-white absolute top-[50%] right-[-6vw] -rotate-90 text-[1.5vw] tracking-[10px]'>WHAT WE DO</p>
      <div className='absolute w-full h-full top-0 left-0'>
        <div className='w-[80vw] grid grid-flow-row-dense lg:grid-cols-3 gap-4 px-4 lg:py-20 absolute top-[calc(30vh_-_100px)] items-center left-[10vw]'>
          <div className='grid lg:grid-cols-3 gap-8 py-4'>
            <div>
              <span className='font-ceraReg text-white text-[5vw] lg:text-[3vw] md:text-[4vw] sm:text-[4.5vw] xs:text-[5vw]'>Our</span> <span className='font-ceraBold text-white text-[5vw] lg:text-[3vw] md:text-[4vw] sm:text-[4.5vw] xs:text-[5vw]'>Purpose</span>
            </div>
          </div>
          <div className='col-span-1 lg:col-span-2 flex flex-col'>
            <div className='flex flex-col lg:flex-row items-center text-center'>
              <div>
                <p className='font-ceraReg text-white text-[2vw] lg:text-[1.5vw] md:text-[2.5vw] sm:text-[3vw] xs:text-[3.5vw] text-left' dangerouslySetInnerHTML={{ __html: purpose.purpose }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
    </>
  )
}
