import React from 'react'
import { useState } from 'react'
import Bg from '../assets/contact bg@2x.png'
import Fb from '../assets/facebook@2x.png'
import Tw from '../assets/twitter@2x.png'

export const Contact = (props) => {

    const [state,setState] = useState({
        contactName: '',
        contactNumber: '',
        contactEmail: '',
        contactMessage: '',
        contactStatus: ''
    })

    const handleChange = e => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        })
    }

    const submitForm = (e) => {
        console.log(state)
        const {
            contactName,
        contactNumber,
        contactEmail,
        contactMessage,
        } = state;

        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
            console.log('OUTPUT: ' + xhr.responseText)
            alert (xhr.responseText);
        });

        var url = process.env.REACT_APP_MAILER + 'index.php?&contactEmail=' + contactEmail +
        '&contactName=' + contactName +
        '&contactNumber=' + contactNumber +
        '&contactMessage=' + contactMessage

        xhr.open('GET' , url)
        xhr.send()

        e.preventDefault();
    }

    return (
        <div id='contactus' className="relative justify-center itmes-center h-[calc(100vh_-_100px)]">
            <img src={Bg} alt='/' className="object-cover bg-no-repeat bg-center w-full h-full" />
            <p className='font-quicksandBold opacity-40 text-white absolute top-[50%] md:left-[-1vw] left-[-5vw] -rotate-90 text-[1.5vw] tracking-[10px]'>CONTACT US</p>
            <form onSubmit={submitForm}>
                <div className="w-[80vw] absolute left-[10vw] justify-start items-center top-[7vh] h-[65vh] px-12 grid grid-rows-5 grid-cols-3 md:gap-y-5 gap-y-1 gap-x-10">
                    <div className='w-full h-full row-span-1 md:col-span-1 col-span-3 items-center flex'>
                        <p className='text-white font-ceraBold md:text-[3vw] text-[4vw]'>Contact Us</p>
                    </div>
                    <div className='w-full h-full row-span-1 md:col-span-2 col-span-3 items-center justify-end  flex'>
                        <p className='text-white font-quicksandBold md:text-[1.5vw] text-[2.5vw] text-right'>Enter your message here and we’ll get back to soon!</p>
                    </div>
                    <div className='w-full h-full row-span-1 md:col-span-1 col-span-3 items-center flex'>
                        <div className='w-full h-[70%] rounded-lg border-inherit bg-white bg-opacity-30 focus-within:border-basecolor focus-within:border-2 hover:border-basecolor hover:border-2'>
                            <input type="text" name='contactName' value={state.contactName} placeholder='Your name' className='w-[90%] h-full ml-[5%] outline-0 bg-[transparent] placeholder-white placeholder-opacity-100 text-white' onChange={handleChange} />
                        </div>
                    </div>
                    <div className='w-full h-full row-span-1 md:col-span-1 col-span-3 items-center flex'>
                        <div className='w-full h-[70%] rounded-lg border-inherit bg-white bg-opacity-30 focus-within:border-basecolor focus-within:border-2 hover:border-basecolor hover:border-2'>
                            <input type="text" name='contactNumber' value={state.contactNumber} placeholder='Contact Number' className='w-[90%] h-full ml-[5%] outline-0 bg-[transparent] placeholder-white placeholder-opacity-100 text-white' onChange={handleChange} />
                        </div>
                    </div>
                    <div className='w-full h-full row-span-1 md:col-span-1 col-span-3 items-center flex'>
                        <div className='w-full h-[70%] rounded-lg border-inherit bg-white bg-opacity-30 focus-within:border-basecolor focus-within:border-2 hover:border-basecolor hover:border-2'>
                            <input type="text" name='contactEmail' value={state.contactEmail} placeholder='Email Address' className='w-[90%] h-full ml-[5%] outline-0 bg-[transparent] placeholder-white placeholder-opacity-100 text-white' onChange={handleChange} />
                        </div>
                    </div>
                    <div className='w-full h-full row-span-2 col-span-3 items-center flex'>
                        <div className='w-full h-[70%] rounded-lg border-inherit bg-white bg-opacity-30 focus-within:border-basecolor focus-within:border-2 hover:border-basecolor hover:border-2'>
                            <textarea type="text" name='contactMessage' value={state.contactMessage} placeholder='Your Message Here' className='w-[95%] h-[90%] outline-0 ml-[3%] mt-[1%] bg-[transparent] placeholder-white placeholder-opacity-100 text-white' onChange={handleChange} />
                        </div>
                    </div>
                    <div className='w-full md:h-full h-[50px] row-span-1 md:col-span-1 col-span-3 items-center flex'>
                        <button className='w-[60%] h-[70%] rounded-md bg-basecolor text-white font-ceraMid md:text-[1vw] text-[2vw]'>SEND MESSAGE</button>
                    </div>
                    <div className='w-full md:h-full h-[50px] row-span-1 md:col-span-2 col-span-3 items-center justify-end  flex'>
                        <p className='text-white font-ceraReg md:text-[1vw] text-[2vw] text-right flex'>You can also find us on our social media accounts:</p>
                        <a href={props.data.fb} className='h-full w-auto flex'><img src={Fb} alt="/" className='md:h-[30%] h-[40%] w-auto my-auto md:ml-5 ml-5 md:mr-4 mr-2' /></a>
                        <a href={props.data.tw} className='h-full w-auto flex'><img src={Tw} alt="/" className='md:h-[30%] h-[40%] w-auto my-auto mx-2 md:mx-1' /></a>
                    </div>
                </div>
            </form>
        </div>
    )
}
