import API from "./api";

const getAbout = () => {
  return API.get("about")
    .then((responseText) => {
      return responseText.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getPrograms = () => {
  return API.get("programs")
    .then((responseText) => {
      return responseText.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getEvents = () => {
  return API.get("events")
    .then((responseText) => {
      return responseText.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getCareer = () => {
  return API.get("careers")
    .then((responseText) => {
      return responseText.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getHome = () => {
  return API.get("dashboard")
    .then((responseText) => {
      return responseText.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getPurpose = () => {
  return API.get("purpose")
    .then((responseText) => {
      return responseText.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const donate = (data) => {
  return API.post("donate", data)
    .then((responseText) => {
      console.log(responseText.data, "test");
      return responseText.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export {
  getAbout,
  getPrograms,
  getEvents,
  getCareer,
  getHome,
  getPurpose,
  donate,
};
