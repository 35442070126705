import React from 'react'
import Bg from './../assets/footer.png'

export const Footer = () => {
    return (
        <div id='footer' className='w-full h-auto md:mt-[-100px] mt-[-98px] items-center flex z-30'>
            <img src={Bg} alt='/' className="relative object-cover md:object-fill bg-no-repeat bg-center w-full h-auto" />
            <p className='absolute text-center mt-[10%] w-full text-white md:text-[1.2vw] text-[2.3vw] font-ceraReg'>© 2022 Water Flow Foundation | All Rights Reserved</p>
        </div>
    )
}
