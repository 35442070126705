/************************************************
                Date: 06/26/2022                
                Author: Jac De Villa             
**************************************************/

import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DataModel from "./dataModel/data";
import { Home } from "./components/Home";
import { Navbar } from "./components/Navbar";
import { Purpose } from "./components/Purpose";
import { News } from "./components/News";
import { About } from "./components/About";
import { Programs } from "./components/Programs";
import { Contribute } from "./components/Contribute";
import { Team } from "./components/Team";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  offset: 100,
});

const App = () => {
  const [passData, setPassData] = useState(DataModel);
  useEffect(() => {
    setPassData(DataModel);
  }, []);

  return (
    <div className="bg-white w-full h-[100vh]">
      <div className="top-[100px] relative">
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Home data={passData.Banner} />
                  <Purpose data={passData.Purpose} />
                </>
              }
            />
            <Route path="" element={<Purpose data={passData.Purpose} />} />
            <Route
              path="/newsandevents"
              element={<News data={passData.News} />}
            />
            <Route path="/aboutus" element={<About />} />
            <Route
              path="/programs"
              element={<Programs data={passData.Program} />}
            />
            <Route
              path="/contribute"
              element={
                <>
                  <Contribute data={passData.Contri} />
                </>
              }
            />
            <Route path="/career" element={<Team data={passData.Team} />} />
            <Route
              path="/contactus"
              element={<Contact data={passData.Social} />}
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
