import React, { useState, useEffect } from 'react';
import { getHome } from './../api/call'
import LoadingIndicator from './LoadingIndicator';
import { AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai'

export const Home = (props) => {

  const [slide, setSlide] = useState(0);
  const [home, setHome] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const prevSlide = () => {
    setSlide(slide === 0 ? home.length - 1 : slide - 1);
  };
  const nextSlide = () => {
    setSlide(slide === home.length - 1 ? 0 : slide + 1);
  };

    useEffect(() => {
        if (!home) dataHome();
    });

    const dataHome = async () => {
        const data = await getHome();
        setHome(data);
        setTimeout(() => setIsLoading(false), 500);
    };

  return (
    <>
    {isLoading && <LoadingIndicator />}
    {!isLoading && (
    <div id='home' className='relative justify-center itmes-center h-[calc(100vh_-_100px)]'>
      <AiOutlineCaretLeft
        onClick={prevSlide}
        className='absolute top-[40%] text-[3vw] text-white opacity-80 cursor-pointer left-8'
      />
      <AiOutlineCaretRight
        onClick={nextSlide}
        className='absolute top-[40%] text-[3vw] text-white opacity-80 cursor-pointer right-8'
      />
      {home.map((j, i) => (
        <div key={`${j.title}-${i}`} className={i === slide ? 'opacity-100 h-full transition-all duration-500' : 'opacity-0 '}>
          {i === slide && (
            <>
              <img className="object-cover bg-no-repeat bg-center w-full h-full" src={j.image_url_path} alt='/' />
              <div className="w-[80vw] absolute left-14 justify-start items-center top-[calc(60vh_-_100px)] h-auto px-12">
                <p className="font-ceraBold w-full text-white text-[3vw] 2xl:text-[5vw]">{j.title}</p>
              </div>
              <div className="w-[80vw] absolute left-14 justify-start items-center top-[calc(70vh_-_100px)] h-auto px-12">
                <p className="font-ceraReg w-full text-white text-[1.5vw] 2xl:text-[2vw]">{j.description}</p>
              </div>
            </>
          )}
        </div>
      ))}
      <div className='w-full h-[5%] absolute top-[calc(80vh_-_100px)] flex justify-center'>
      <ul className='h-full w-auto absolute'>
          {
            home.map((j,i) => (
              <li key={`${j.title}-${i}`} className='h-full w-auto p-1 border-inherit inline-block'>
                <div className={i === slide ? 'w-[2vw] h-2 bg-white opacity-40':'w-[2vw] h-2 bg-white'}></div>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
    )}
    </>
  )
}

export default Home