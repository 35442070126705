import React, { useState, useEffect } from 'react';
import {getAbout} from './../api/call'
import LoadingIndicator from './LoadingIndicator';
import Bg from './../assets/banner@2x.png';

export const About = () => {

  const [aboutUs, setAboutUs] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!aboutUs) dataAboutUs();
  });

  const dataAboutUs = async () => {
    const data = await getAbout();
    setAboutUs(data);
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <>
    {isLoading && <LoadingIndicator />}
    {!isLoading && (
      <div id='aboutus' className="relative justify-center itmes-center h-[calc(100vh_-_100px)] ease-in">
        <img src={Bg} alt='/' className="object-cover bg-no-repeat bg-center w-full h-full" />
        <p className='font-quicksandBold opacity-40 text-white absolute top-[50%] md:left-[-1vw] left-[-5vw] -rotate-90 text-[1.5vw] tracking-[10px]'>ABOUT US</p>
        <div className="md:w-[50vw] w-[70vw] absolute left-[8vw] justify-start items-center top-[3vh] h-[70vh] px-12">
          <p className='text-white font-ceraMid md:text-[3.5vw] text-[3.5vw]'>About Us</p>
          <div className='pt-4'><span className='text-white font-ceraReg md:text-[2.5vw] text-[3vw]'>Our</span> <span className='text-white font-ceraBold md:text-[2.5vw] text-[3vw]'>Vision</span></div>
          <p className='text-white font-ceraReg md:text-[1.3vw] text-[2.5vw] pt-4'>{aboutUs.vision}</p>
          <div className='pt-4'><span className='text-white font-ceraReg md:text-[2.5 vw] text-[3vw]'>Our</span> <span className='text-white font-ceraBold md:text-[2.5vw] text-[3vw]'>Mission</span></div>
          <p className='text-white font-ceraReg md:text-[1.3vw] text-[2.5vw] pt-4'>{aboutUs.mission}</p>
        </div>
      </div>
     )
    }
    </>
  )
}
