import React, { useState, useEffect } from 'react';
import { getCareer } from './../api/call'
import LoadingIndicator from './LoadingIndicator';
import listImg from './../assets/Group 453@2x.png';

export const Team = () => {

    const [career, setCareer] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const longMonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    useEffect(() => {
        if (!career) dataCareer();
    });

    const dataCareer = async () => {
        const data = await getCareer();
        setCareer(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    const convertDate = (longDate) => {
        let date = new Date(longDate);
        let dateMDY = `${longMonth[date.getMonth()]} ${date.getDate() + 1}, ${date.getFullYear()}`;
        return dateMDY
      };

    return (
        <>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
        <div id='ourteam' className='max-w-[80vw] h-auto mx-auto py-10 px-4 text-center relative justify-center'>
            <p className='font-quicksandBold opacity-40 text-fontColor absolute top-[50%] md:left-[-12vw] left-[-15vw] -rotate-90 text-[1.5vw] tracking-[10px]'>APPLY NOW</p>
            <div><span className='text-fontColor font-ceraReg md:text-[2vw] text-[3vw] '>Be Part of</span> <span className='text-fontColor font-ceraBold md:text-[2vw] text-[3vw]'>Our Team</span></div>
            <p className='text-[#575757] font-ceraMid md:text-[1.2vw] text-[1.5vw] md:w-[62%] w-[75%] m-auto'>Our team is hiring new people to join our team! Expand your knowledge while earning! For more information you may simply leave us a message!</p>
            {career.length > 0 ? <div className='w-full h-auto'>
                <div className='md:w-[20%] w-[35%] md:h-[50px] h-[30px] bg-basecolor mt-4 items-center flex'>
                    <p className='w-full text-center text-white font-ceraItalic md:text-[1vw] text-[1.5vw] tracking-[3px]'><b>WE ARE HIRING</b></p>
                </div>
                {career.map((j, i) => (
                    0 <= i ?
                    <div key={`${j.team_title}-${i}`} className='w-full h-auto grid grid-rows-5 grid-cols-5 gap-3 pt-5'>
                        <div className='w-full h-full row-span-5 md:col-span-1 col-span-5'>
                            <img className="object-cover bg-no-repeat bg-center w-full h-full" src={listImg} alt='/' />
                        </div>
                        <div className='w-full h-full row-span-1 col-span-2'>
                            <p className='font-ceraBold text-fontColor text-xl text-left w-full h-full'>{j.title}</p>
                        </div>
                        <div className='w-full h-full row-span-1 md:col-span-2 col-span-3'>
                            <p className='font-ceraMid text-[#8e8e8e] text-md text-right w-full h-full'>{convertDate(j.created_at)}</p>
                        </div>
                        <div className='w-full h-full row-span-3 md:col-span-4 col-span-5 Setting__Comment'>
                            <p className='font-ceraMid text-[#575757] text-md text-left w-full h-full' dangerouslySetInnerHTML={{ __html: j.description }}></p>
                        </div>
                        <div className='w-full h-full row-span-1 col-span-4'>
                            <p className='font-ceraMid text-fontColor text-l text-left w-full h-full'>{j.position}</p>
                        </div>                    
                    </div>
                    :null
                ))}
            </div>:<div className='w-full h-[calc(100vh-271px)]'></div>}
        </div>
        )
    }
    </>
    )
}
